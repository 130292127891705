<template>
    <div class="row g-0 stepper-container">
        <a 
            @click="checkFormValidation('app-details')"
            class="col pt-2 mb-2 stepper-link noselect-text"
            :class="{'done-step': stepStatuses.details, 'active': activeRoute === 'app-details'}"
        >
            <div class="d-none d-lg-flex" :class="{ 'fw-bold': activeRoute === 'app-details' }">
                <img v-if="stepStatuses.details == 0" src="@/assets/icons/circle.svg" alt="circle-icon" class="me-2">
                <img v-else-if="stepStatuses.details == 1" src="@/assets/icons/exclamation.svg" alt="exclamation-icon" class="me-2">
                <img v-else src="@/assets/icons/check.svg" alt="check-icon" class="me-2">
                Applicant(s) Details
            </div>
        </a>

        <a 
            @click="checkFormValidation('app-incomes')"
            class="col pt-2 mb-2 stepper-link noselect-text" 
            :class="{'disabled-stepper': stepStatuses.details == 0, 'done-step': stepStatuses.incomes, 'active': activeRoute === 'app-incomes'}"
        >
            <div class="d-none d-lg-flex" :class="{ 'fw-bold': activeRoute === 'app-incomes' }">
                <img v-if="stepStatuses.incomes == 0" src="@/assets/icons/circle.svg" alt="circle-icon" class="me-2">
                <img v-else-if="stepStatuses.incomes == 1" src="@/assets/icons/exclamation.svg" alt="exclamation-icon" class="me-2">
                <img v-else src="@/assets/icons/check.svg" alt="check-icon" class="me-2">
                Income
            </div>
        </a>

        <a 
            @click="checkFormValidation('app-expenses')" 
            class="col pt-2 mb-2 stepper-link noselect-text" 
            :class="{'disabled-stepper': stepStatuses.details == 0, 'done-step': stepStatuses.expenses, 'active': activeRoute === 'app-expenses'}"
        >
            <div class="d-none d-lg-flex" :class="{ 'fw-bold': activeRoute === 'app-expenses' }">
                <img v-if="stepStatuses.expenses == 0" src="@/assets/icons/circle.svg" alt="circle-icon" class="me-2">
                <img v-else-if="stepStatuses.expenses == 1" src="@/assets/icons/exclamation.svg" alt="exclamation-icon" class="me-2">
                <img v-else src="@/assets/icons/check.svg" alt="check-icon" class="me-2">
                Expenses
            </div>
        </a>

        <a 
            @click="checkFormValidation('app-assets')" 
            class="col pt-2 mb-2 stepper-link noselect-text" 
            :class="{'disabled-stepper': stepStatuses.details == 0, 'done-step': stepStatuses.assets, 'active': activeRoute === 'app-assets'}"
        >
            <div class="d-none d-lg-flex" :class="{ 'fw-bold': activeRoute === 'app-assets' }">
                <img v-if="stepStatuses.assets == 0" src="@/assets/icons/circle.svg" alt="circle-icon" class="me-2">
                <img v-else-if="stepStatuses.assets == 1" src="@/assets/icons/exclamation.svg" alt="exclamation-icon" class="me-2">
                <img v-else src="@/assets/icons/check.svg" alt="check-icon" class="me-2">
                Assets
            </div>
        </a>

        <a 
            @click="checkFormValidation('app-liabilities')" 
            class="col pt-2 mb-2 stepper-link noselect-text" 
            :class="{'disabled-stepper': stepStatuses.details == 0, 'done-step': stepStatuses.liabilities, 'active': activeRoute === 'app-liabilities'}"
        >
            <div class="d-none d-lg-flex" :class="{ 'fw-bold': activeRoute === 'app-liabilities' }">
                <img v-if="stepStatuses.liabilities == 0" src="@/assets/icons/circle.svg" alt="circle-icon" class="me-2">
                <img v-else-if="stepStatuses.liabilities == 1" src="@/assets/icons/exclamation.svg" alt="exclamation-icon" class="me-2">
                <img v-else src="@/assets/icons/check.svg" alt="check-icon" class="me-2">
                Liabilities
            </div>
        </a>
    </div>

    <!-- <div class="row g-0 d-block d-lg-bloce ">
        <div class="col-12">
            {{ activeStepper }}
        </div>
    </div> -->
</template>

<style lang="scss" scoped>
    .stepper-container {
      background-color: $gray-400;
        .stepper-link {
            border-top: 4px solid $gray-800;
            font-size: $font-size-14;
            color: $gray-700;
            text-decoration: none;
            cursor: pointer;
            &.done-step {
                //color: $cyan-600;
                // border-top: 4px solid $cyan-500;
                &.active {
                    color: $cyan-600;
                    border-top: 4px solid $cyan-600;
                }
            }
            &.active {
                color: $cyan-600;
                border-top: 4px solid $cyan-600;
            }
            &:hover {
                color: $cyan-600;
            }
        }
    }

    .disabled-stepper {
        pointer-events: none;
        color: $gray-200 !important;
    }
</style>

<script>
import useApplication from '@/composables/useApplication';
import useConstants from '@/composables/useConstants';
import { useRoute } from "vue-router";
import { computed } from 'vue';

export default {
    setup() {
        const route = useRoute();
        const { stepStatuses } = useApplication();
        const { isMobile } = useConstants();

        const activeRoute = computed(() => {
            return route.name;
        });

        const activeStepper = computed(() => {
            return route.meta.stepper;
        });

        const checkFormValidation = (routeName) => {
            if (!isMobile.value) {
                const btnContinue = document.querySelector('.btn-continue');
                if (btnContinue) {
                    btnContinue.setAttribute('data-route', routeName);
                    btnContinue.setAttribute('data-src', 'stepper');
                    btnContinue.setAttribute('redirect', 'true');
                    btnContinue.click();
                }
            }
        };

        return {
            checkFormValidation,

            activeRoute,
            activeStepper,
            stepStatuses ,

            isMobile
        };
    }
}
</script>