import { computed } from "vue";
import store from '@/store';

export default function useConstants() {

  const getConstants = async () => {
    try {
      await store.dispatch('constants/getConstants');
    } catch (error) {
      throw Error('Error fetching data');
    }
  }

  const browserWidth = computed(() => {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
  })

  const isMobile = computed(() => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if (browserWidth.value >= 1024) {
        return false
      } else {
        return true
      }
    } else {
      if (browserWidth.value <= 991) {
        return true
      } else {
        return false
      }
    }
  })

  const userTypes = computed(() => store.getters['constants/getUserTypes']);
  const propertyTypes = computed(() => store.getters['constants/getPropertyTypes']);
  const durationTypes = computed(() => store.getters['constants/getDurationTypes']);
  const bankAccountTypes = computed(() => store.getters['constants/getBankAccountTypes']);
  const employmentTypes = computed(() => store.getters['constants/getEmploymentTypes']);
  const loanTypes = computed(() => store.getters['constants/getLoanTypes']);
  const documentTypes = computed(() => store.getters['constants/getDocumentTypes']);
  const incomeTypes = computed(() => store.getters['constants/getIncomeTypes']);
  const expenseTypes = computed(() => store.getters['constants/getExpenseTypes']);
  const assetTypes = computed(() => store.getters['constants/getAssetTypes']);
  const liabilityTypes = computed(() => store.getters['constants/getLiabilityTypes']);
  const declineReasons = computed(() => store.getters['constants/getDeclineReasons']);
  const rateTypes = computed(() => store.getters['constants/getApplicationOfferRateTypes']);
  const rejectReasons = computed(() => store.getters['constants/getRejectReasons']);
  const applicationStatuses = computed(() => store.getters['constants/getApplicationStatuses']);

  return {
    getConstants,
    userTypes,
    propertyTypes,
    durationTypes,
    bankAccountTypes,
    employmentTypes,
    loanTypes,
    documentTypes,
    incomeTypes,
    expenseTypes,
    assetTypes,
    liabilityTypes,
    declineReasons,
    rateTypes,
    rejectReasons,
    applicationStatuses,
    isMobile
  }
}
