// import { ActionContext } from "vuex";
import { State } from '.';
import { Loan } from '@/models';

// type Context = ActionContext<Loan, State>;

export const loan = {
    namespaced: true,
    state: {
        id: 0,
        selectedLoanType: '',
		preapproval: '0',
		address: '',
		purchaseprice: 0,
		deposit: 0,
		borrow: 0,
		term: 30,
		useCache: false
    },

	actions: {
        // async getLoanDetails(context: Context, uuid: string) {
            // api call here
            // console.log(context);
            // console.log(uuid);
        // },
    },

	mutations: {
        setData(state: State, loan: Loan) {
            state.loan = loan;
        },
    }
}
