
import AuthHeader from '@/components/Common/AuthHeader.vue';
import AuthFooter from '@/components/Common/AuthFooter.vue';
import { useRoute } from 'vue-router';
//import { useRouter } from 'vue-router';
export default {
    components: {
        AuthHeader,
        AuthFooter
    },
    setup() {
        const route = useRoute();
        return { route }
    }
}
