import axios from 'axios';

class ConstantsService {
    getConstants() {
        return axios
            .get('/constants')
            .then(response => {
                return response;
            });
    }

    getDocumentLink(uuid: string, documentId: number) {
        return axios
            .get(`/${uuid}/${documentId}/document-link`)
            .then(response => {
                return response;
            });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    debounce(func: Function, timeout = 500) {
        let timer: ReturnType<typeof setTimeout>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (...args: any[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

}

export default new ConstantsService();