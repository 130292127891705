<template>
  <div class="container">
    <BaseHeader v-if="currentHeader === 'application'" :key="remountHeader"/>
    <BaseHeader v-else-if="currentHeader === 'loan'" :key="`${remountHeader}-loan`"/>
    <slot/>
  </div>
</template>

<script>
import BaseHeader from '@/components/Common/BaseHeader.vue'
//import LoanHeader  from '@/components/Common/LoanHeader.vue'
import {onMounted, onUnmounted} from 'vue-demi'
import {useRoute} from 'vue-router'
import {computed, ref} from 'vue';

export default {
  components: {
    BaseHeader,
    //LoanHeader
  },
  setup() {
    let remountHeader = ref(0)
    onMounted(() => {
      document.body.classList.add('with-fixed-header')
    })

    onUnmounted(() => {
      document.body.classList.remove('with-fixed-header')
    })

    const route = useRoute();
    const currentHeader = computed(() => route.meta.header)

    window.addEventListener('popstate', () => {
      remountHeader.value++
    })

    return {currentHeader, remountHeader}
  }
}
</script>