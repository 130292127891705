<template>
<div>
    <div class="fixed-top">
        <nav class="navbar navbar-expand-lg base bg-white">
            <div class="container">
                <a class="navbar-brand d-flex" href="#">
                    <div class="logo-container d-flex justify-content-center align-items-center me-2">
                        <img src="@/assets/images/logo.png" alt="logo" class="logo">
                    </div>
                    <span class="header-title text-xl font-weight-bold text-gray-900">Anteup</span>
                    <span v-if="!checkJustVerified()" class="header-title sub-text text-xl font-weight-medium ps-1 d-none d-lg-block text-gray-200">Statement of Position</span>
                </a>
                <div class="justify-content-end d-block d-lg-none" v-if="showApplicationButtons">
                    <button 
                        class="btn btn-outline-secondary d-inline-flex me-2 me-sm-3 me-md-3" 
                        @click="saveForLater" 
                        v-if="data.currentRouteName !== 'app-details'"
                    >
                        <img src="@/assets/icons/save.svg" alt="save-icon" class="me-1 me-sm-2 me-md-2 d-none d-lg-block"> Save
                    </button>
                    <router-link v-if="data.currentRouteName === 'app-details' && !isLoggedIn" :to="{ name: 'login' }">
                        <button type="button" class="btn btn-outline-secondary d-inline-flex me-2 me-sm-3 me-md-3">
                            Login 
                        </button>
                    </router-link>
                    <button class="btn btn-primary" @click="checkFormValidation">{{ data.currentRouteName == 'app-liabilities' ? 'Save & Complete' : 'Continue' }}</button>
                </div>
                <!-- for mobile toggle -->
                <!-- <button v-if="isLoggedIn && !showApplicationButtons && !showSOPButtons" @click="showSlider = true" class="navbar-toggler d-flex d-lg-none" type="button">
                    <img v-if="isLoggedIn && !showApplicationButtons && !showSOPButtons" @click="showSlider = true" src="@/assets/icons/menu.svg" class="me-2"/>
                </button> -->
                <img v-if="isLoggedIn && !showApplicationButtons && !showSOPButtons" @click="showSlider = true" src="@/assets/icons/menu.svg" class="me-2 d-block d-lg-none"/>

                <div v-if="showSOPButtons" class="d-flex justify-content-end py-4 d-lg-none">
                    <router-link :to="{ name: 'consumer-applications' }" v-if="!enableApplicationOnly">
                        <button type="button" class="btn btn-sm rounded-3 btn-outline-secondary me-2 me-sm-3 me-md-3">
                            Dashboard 
                        </button>
                    </router-link>
                    <router-link v-if="enableEditing" :to="{ name: !canApplyLoan ? 'app-statement-of-position' : 'loan-purpose' }">
                        <button class="btn btn-primary btn-sm rounded-3" :disabled="!canApplyLoan">
                            Apply for a Loan
                        </button>
                    </router-link>
                </div>

                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav me-auto mb-2 mb-md-0" v-if="checkJustVerified()">
                        <li class="nav-item">
                            <router-link class="nav-link d-flex font-weight-medium consumer-link" :to="{name: 'app-statement-of-position'}">
                                <img src="@/assets/icons/bars.svg" alt="bars" class="me-3">
                                Statement of Position
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="!enableApplicationOnly">
                            <router-link class="nav-link d-flex font-weight-medium consumer-link" :to="{name: 'consumer-applications'}">
                                <img src="@/assets/icons/pencil.svg" alt="pencil" class="me-3">
                                Applications
                            </router-link>
                        </li>
                    </ul>
                    <div class="d-flex justify-content-end w-100 d-none d-lg-flex" v-if="!checkJustVerified()">
                        <button 
                            class="btn btn-outline-secondary d-inline-flex me-2 me-sm-3 me-md-3" 
                            @click="saveForLater" 
                            v-if="data.currentRouteName !== 'app-details'"
                        >
                            <i class="fa-regular fa-floppy-disk mt-1"></i> <span class="ps-1"> Save for later</span>
                        </button>
                        <router-link v-if="data.currentRouteName === 'app-details' && !checkJustVerified()" :to="{ name: 'login' }">
                            <button type="button" class="btn btn-outline-secondary d-inline-flex me-2 me-sm-3 me-md-3">
                                Login 
                            </button>
                        </router-link>
                        <button class="btn btn-primary" @click="checkFormValidation">{{ data.currentRouteName == 'app-liabilities' ? 'Save & Complete' : 'Continue' }}</button>
                    </div>

                    <div 
                        class="d-flex cursor-pointer noselect-text" 
                        v-if="checkJustVerified()"
                        tabindex="0"
                        @click="data.showSettingsContainer = !data.showSettingsContainer" 
                        @blur="data.showSettingsContainer = false" 
                        @focusout="data.showSettingsContainer = false"
                    >
                        <div class="me-3">
                            <h4 class="mb-0 text-sm font-weight-medium text-gray-900" v-if="checkUserName">
                                {{ checkUserName }}
                            </h4>
                            <!-- <h4 class="mb-0 text-sm font-weight-medium text-gray-900" v-else>No Name</h4> -->
                            <span class="text-sm text-gray-500">{{ checkUserEmail }}</span>
                        </div>
                        <img src="@/assets/icons/chevron-down.svg" alt="chevron-down">
                    </div>
                </div>
            </div>
        </nav>

        <!-- Stepper Tab -->
        <div class="container" v-if="data.hasStepper">
            <StepperTab />    
        </div>

        <!-- Settings Container -->
        <div class="container d-flex justify-content-end mt-2 noselect-text" v-if="data.showSettingsContainer">
            <div class="position-fixed consumer-settings-container">
                <ul class="mb-0 list-unstyled p-2">
                    <li v-if="showUserAccountSettings === true" class="p-2">
                        <a @mousedown="handleSettingLinkEvent('consumer-settings')" @click.stop="$event.stopImmediatePropagation()" class="d-flex cursor-pointer align-items-center text-decoration-none text-gray-900">
                            <img src="@/assets/icons/cog.svg" alt="cog" class="me-2">
                            Account Settings
                        </a>
                    </li>
                    <li class="p-2">
                        <a @mousedown="handleSettingLinkEvent('consumer-change-password')" class="d-flex cursor-pointer align-items-center text-decoration-none text-gray-900">
                            <img src="@/assets/icons/padlock.svg" alt="padlock" class="me-2">
                            Change Password
                        </a>
                    </li>
                    <li class="p-2">
                        <a @mousedown="showLogoutModal = true" class="d-flex align-items-center text-decoration-none cursor-pointer text-danger text-error-600">
                            <img src="@/assets/icons/exit.svg" alt="exit" class="me-2">
                            Log out
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <BaseModal
            :showModal="showLogoutModal"
            :primary="'Log Out'"
            :secondary="'Cancel'"
            @secondary="showLogoutModal = false"
            @primary="handleLogout">
            <div class="row my-1">
                <h4 class="text-gray-900 text-lg font-weight-semibold">Log Out</h4>
            </div>
            <div class="row mb-3">
                <p>Are you sure you want to log out?</p>
            </div>
        </BaseModal>
        <BaseAlert
            type="success"
            :custom="true"
            :messages="messages"
            :isMobile="isMobile"
            :isShowIcon="true"
            @close="messages = []"
            v-if="messages.length > 0"
        />

    </div>
    <BaseSlider
        :show-slider="showSlider"
        @close-slider="showSlider = false"
        :mobileNavigation="true"
        :hasCustomFooter="true"
    >
        <div class="row">
            <div class="col-12 divider-line">
                <ul class="mb-0 list-unstyled p-2">
                    <li @click="showSlider = false">
                        <router-link class="d-flex font-weight-medium consumer-link" :to="{name: 'app-statement-of-position'}">
                            <img src="@/assets/icons/bars.svg" alt="bars" class="me-3">
                            Statement of Position
                        </router-link>
                    </li>
                    <li @click="showSlider = false">
                        <router-link class="d-flex font-weight-medium consumer-link" :to="{name: 'consumer-applications'}">
                            <img src="@/assets/icons/pencil.svg" alt="pencil" class="me-3">
                            Applications
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="col-12">
                <ul class="mb-0 list-unstyled p-2">
                    <li class="p-2">
                        <a @mousedown="handleSettingLinkEvent('consumer-settings')" @click.stop="$event.stopImmediatePropagation()" class="d-flex cursor-pointer align-items-center text-decoration-none text-gray-900">
                            <img src="@/assets/icons/cog.svg" alt="cog" class="me-2">
                            Account Settings
                        </a>
                    </li>
                    <li class="p-2">
                        <a @mousedown="handleSettingLinkEvent('consumer-change-password')" class="d-flex cursor-pointer align-items-center text-decoration-none text-gray-900">
                            <img src="@/assets/icons/padlock.svg" alt="padlock" class="me-2">
                            Change Password
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div class="col-12 sticky-bottom">
                <a @mousedown="mobileLogout()" class="d-flex align-items-center text-decoration-none cursor-pointer text-danger text-error-600">
                    <img src="@/assets/icons/exit.svg" alt="exit" class="me-2">
                    Log out
                </a>
            </div> -->
        </div>
        <template v-slot:custom-footer>
            <div class="d-flex align-items-center">
                <a @mousedown="mobileLogout()" class="d-flex align-items-center text-decoration-none cursor-pointer text-danger text-error-600">
                    <img src="@/assets/icons/exit.svg" alt="exit" class="me-2">
                    Log out
                </a>
            </div>
        </template>
    </BaseSlider>
</div>
</template>

<style lang="scss" scoped>
    nav.base {
        border-bottom: 1px solid $gray-100;
    }

    .divider-line {
        border-bottom: 1px solid $gray-800;
    }
    .consumer-settings-container {
        background-color: $white;
        min-width: 242px;
        border: 1px solid $gray-100;
        border-radius: 8px;
        top: 65px;
        box-shadow: 0px 12px 16px -4px rgba($gray-900, 0.08), 0px 4px 6px -2px rgba($gray-900, 0.03);
    }

    .consumer-link {
        padding: 8px 12px 8px 12px;
        color: $gray-700;
        border-radius: 6px;
        &.router-link-active {
            background-color: #FFFFFF;
            color: #344054;
            > img {
                filter: invert(31%) sepia(76%) saturate(1695%) hue-rotate(168deg) brightness(98%) contrast(94%);
            }
        }
    }

    @media (max-width: 576px) {
        .btn {
            font-size: $font-size-14 !important;
        }
    }
</style>

<script>
import BaseAlert from "@/components/Common/BaseAlert";
import BaseModal from '@/components/Common/BaseModal.vue';
import BaseSlider from "@/components/Common/BaseSlider";
import StepperTab from '@/components/Application/StepperTab.vue'
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useLoading } from 'vue-loading-overlay';
import useApplication from '@/composables/useApplication';
import useAuth from '@/composables/useAuth';
import useConstants from '@/composables/useConstants';

export default {
    components: {
        StepperTab,
        BaseModal,
        BaseAlert,
        BaseSlider
    },
    setup() {
        let data = reactive({
            hasStepper: false,
            showSettingsContainer: false,
            currentRouteName: ''
        });

        const router = useRouter();
        const loading = useLoading();
        const store = useStore();
        const { consumer, status, stepStatuses } = useApplication();
        const { isLoggedIn, authUser } = useAuth();
        const { isMobile } = useConstants();
        const showLogoutModal = ref(false)
        const messages = ref([])
        const showSlider = ref(false)
        const enableApplicationOnly = window['env']['enableApplicationOnly'];

        const mobileLogout = () => {
            showSlider.value = false
            showLogoutModal.value = true
        }

        const route = useRoute();

        if (route.meta.stepper) {
            data.hasStepper = true;
        }

        if (route.name) {
            data.currentRouteName = route.name;
        }

        router.afterEach((to) => {
            data.currentRouteName = to.name;
            data.hasStepper = (to.meta.stepper) ? true : false;
        });

        const showApplicationButtons = computed(() => {
            let routeNames = ['app-details', 'app-incomes', 'app-expenses', 'app-assets', 'app-liabilities'];
            return routeNames.includes(data.currentRouteName);
        });

        const showSOPButtons = computed(() => {
            return data.currentRouteName === 'app-statement-of-position'
        });

        const showUserAccountSettings = computed(() => {
          let valid = false;
          const host = window.location.host;
          const storeConsumerPrefix = window['env']['storeConsumerPrefix']; // eslint-disable-line
          const storeAdminPrefix = window['env']['storeAdminPrefix']; // eslint-disable-line
          const storagePrefix = (host.includes('admin')) ? storeAdminPrefix : storeConsumerPrefix;
          const storedUser = JSON.parse(window.localStorage.getItem(`${storagePrefix}user`));

          if(consumer.value.first_name|| consumer.value.last_name || consumer.value.phone ||
              consumer.value.dob || !storedUser['name'].match(/^ *$/)){
            valid = true;
          }
          return valid;
        });

        const checkJustVerified = () => {
          const storeConsumerPrefix = window['env']['storeConsumerPrefix']; // eslint-disable-line
          let justVerified = JSON.parse(window.localStorage.getItem(`${storeConsumerPrefix}justVerified`));
          if(isLoggedIn.value && authUser.value.email){
            return true;
          }
          if(justVerified === true){
            return true;
          }
          return false;
        }

        const checkUserName = computed(() => {
          const storeConsumerPrefix = window['env']['storeConsumerPrefix']; // eslint-disable-line
          let storedUser = JSON.parse(window.localStorage.getItem(`${storeConsumerPrefix}user`));
          if(storedUser['name'] === ' '){
            return false;
          }
          return storedUser.name;
        });

        const checkUserEmail = computed(() => {
          const storeConsumerPrefix = window['env']['storeConsumerPrefix']; // eslint-disable-line
          let storedUser = JSON.parse(window.localStorage.getItem(`${storeConsumerPrefix}user`));
          return storedUser.email;
        });

        const enableEditing = computed(() => {
            let value = false

            if(status === 'draft' || status === 'sop_completed'){
                value = true;
            }

            if (route.query.editable && route.query.editable == 'false') {
                value = false;
            }

            // console.log('Stat', value, offer_cnt)

            return value
        });
        

        const canApplyLoan = computed(() => {
            let value = false
            if (stepStatuses.value.details == 2 && 
                stepStatuses.value.incomes == 2 && 
                stepStatuses.value.expenses == 2 && 
                stepStatuses.value.assets == 2
            ) {
                value = true;
            }
            if(status === 'submitted'){
                value = false;
            }

            return value
        });

        // messages.value.push('You have completed your first statement of position!')

        const handleLogout = async () => {
            const loader = loading.show({ color: '#088AB2' });

            try {
                await store.dispatch('auth/logout');
                router.push({ name: 'login' });
                loader.hide();
            } catch (err) {
                loader.hide();
            }
        }

        const handleSettingLinkEvent = (routeName) => {
            if (isMobile) {
                showSlider.value = false
            }
            router.push({ name: routeName });
        }

        const checkFormValidation = () => {
            let routeName = data.currentRouteName;

            switch (routeName) {
                case 'app-details':
                    routeName = 'app-incomes';
                    break;
                case 'app-incomes':
                    routeName = 'app-expenses';
                    break;
                case 'app-expenses':
                    routeName = 'app-assets';
                    break;
                case 'app-assets':
                    routeName = 'app-liabilities';
                    break;
            }

            const btnContinue = document.querySelector('.btn-continue');
            if (btnContinue) {
                btnContinue.setAttribute('data-route', routeName);
                if (routeName != 'app-liabilities') {
                    btnContinue.setAttribute('data-src', 'header');
                }
                btnContinue.click();
            }
        };

        const saveForLater = () => {
            const btnSaveLater = document.querySelector('.btn-save-later');
            if (btnSaveLater) {
                btnSaveLater.click();
            }
        }

        return { 
            data,
            isLoggedIn,
            authUser,
            handleLogout,
            handleSettingLinkEvent,
            consumer,
            checkFormValidation,
            saveForLater,
            showLogoutModal,
            isMobile,
            showApplicationButtons,
            showSOPButtons,
            enableEditing,
            canApplyLoan,
            messages,
            showSlider,
            mobileLogout,
            showUserAccountSettings,
            checkJustVerified,
            checkUserName,
            checkUserEmail,
            enableApplicationOnly
        }
    }
}
</script>