import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container d-flex flex-column vh-100" }
const _hoisted_2 = { class: "row g-0 flex-grow-1 justify-content-center align-items-md-center" }
const _hoisted_3 = { class: "col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!
  const _component_AuthFooter = _resolveComponent("AuthFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthHeader),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    ($setup.route.name == 'login' || $setup.route.name == 'register')
      ? (_openBlock(), _createBlock(_component_AuthFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}