import { ActionContext } from "vuex";
import { State } from '.';
import ConstantsService from '@/services/constants.service';
import { Constants } from "@/models";

type Context = ActionContext<{ data: [], useCache: boolean }, State>;

export const constants = {
    namespaced: true,
    state: {
        constants: {
            application_user_types: {},
            property_types: {},
            duration_types: {},
            bank_account_types: {},
            employment_types: {},
            loan_types: {},
            document_types: {},
            income_types: {},
            expense_types: {},
            asset_types: {},
            liability_types: {},
            decline_reasons: {},
            application_offer_rate_types: {},
            reject_reasons:{},
            application_statuses:{},
        },
        useCache: false,
        sop_details: {}
    },

    getters: {
        getUserTypes(state: State) {
            return state.constants.application_user_types;
        },

        getPropertyTypes(state: State) {
            return state.constants.property_types;
        },

        getDurationTypes(state: State) {
            return state.constants.duration_types;
        },

        getBankAccountTypes(state: State) {
            return state.constants.bank_account_types;
        },

        getEmploymentTypes(state: State) {
            return state.constants.employment_types;
        },

        getLoanTypes(state: State) {
            return state.constants.loan_types;
        },

        getDocumentTypes(state: State) {
            return state.constants.document_types;
        },

        getIncomeTypes(state: State) {
            return state.constants.income_types;
        },

        getExpenseTypes(state: State) {
            return state.constants.expense_types;
        },

        getAssetTypes(state: State) {
            return state.constants.asset_types;
        },

        getLiabilityTypes(state: State) {
            return state.constants.liability_types;
        },

        getDeclineReasons(state: State) {
            return state.constants.decline_reasons;
        },

        getApplicationOfferRateTypes(state: State) {
            return state.constants.application_offer_rate_types;
        },

        getRejectReasons(state: State) {
            return state.constants.reject_reasons;
        },

        getApplicationStatuses(state: State) {
            return state.constants.application_statuses;
        }
    },

    actions: {
        async getConstants(context: Context) {
            // Don't call api if the state is already been made
            if (context.state.useCache) return;

            return await ConstantsService.getConstants().then(
                response => {
                    const { data } = response.data;

                    context.commit('setData', data);
                    context.state.useCache = true;

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    },

    mutations: {
        setData(state: State, data: Constants) {
            state.constants = data;
        },
    }
}
