import axios, { AxiosRequestConfig } from 'axios';
import { getWithExpiry } from '@/helpers/storage.helper';

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line 
const storeAdminPrefix = window['env' as any]['storeAdminPrefix' as any]; // eslint-disable-line 

export function httpTokenInterceptor() {
    axios.interceptors.request.use((request: AxiosRequestConfig) => {

        const storagePrefix = (request.url?.includes('/admin')) ? storeAdminPrefix : storeConsumerPrefix;
        const accessToken = getWithExpiry(`${storagePrefix}access_token`);

        if (accessToken) {
            request.headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            };
        } 
        
        // else {
        //     localStorage.removeItem(`${storagePrefix}access_token`);
        //     localStorage.removeItem(`${storagePrefix}refresh_token`);
        //     localStorage.removeItem(`${storagePrefix}user`);
        // }

        return request;
    });
}