<template>
    <header class="d-flex align-items-center">
        <div class="d-flex me-auto">
            <div class="logo-container d-flex justify-content-center align-items-center me-2">
                <img src="@/assets/images/logo.png" alt="logo" class="logo">
            </div>
            <span class="header-title text-xl font-weight-bold d-none d-sm-block d-md-block">Anteup</span>
        </div>
    </header>
</template>

<style lang="scss">
    // 
</style>