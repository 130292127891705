<template>
    <br/>
    <div class="slide" :class="{'slide-in': showSlider, 'mobile': isMobile}">
        <div class="slide-header">
            <div class="d-flex align-items-center">
                <img 
                    v-if="showBackButton"
                    @click="onClickBackButton"
                    class="me-3 cursor-pointer"
                    src="@/assets/icons/arrow-left.svg"
                    alt="arrow-left-icon"
                >
                <div v-if="mobileNavigation" class="d-flex me-auto">
                    <div class="logo-container d-flex justify-content-center align-items-center me-2">
                        <img src="@/assets/images/logo.png" alt="logo" class="logo">
                    </div>
                    <span class="header-title text-xl font-weight-bold text-gray-900">Anteup</span>
                </div>
                <span v-else class="me-auto font-weight-semibold"> {{ headerTitle }}</span>
                <button
                    v-if="showCloseButton"
                    @click="closeSlider"
                    class="btn btn-outline-secondary d-flex align-items-center justify-content-center btn-slider-close"
                >
                    <img src="@/assets/icons/times.svg" alt="times-icon" class="times-icon">
                </button>
            </div>
        </div>
        <div class="slide-body" :class="{'full-height': !showFooter, 'with-custom-footer': customFooterBig&&!isMobile, 'with-custom-footer-mobile': hasCustomFooter&&isMobile}">
            <slot></slot>
        </div>
        <div class="slide-footer" :class="{'d-flex': !hasCustomFooter, 'align-items-center': !hasCustomFooter, 'justify-content-end': !hasCustomFooter}" v-if="showFooter">
            <slot v-if="hasCustomFooter" name="custom-footer"></slot>
            
            <div v-else>
                <a
                    @click="onClickOptionalButton"
                    class="me-auto text-decoration-none text-sm font-weight-medium cursor-pointer"
                    v-if="optionalButtonLabel"
                >
                    {{ optionalButtonLabel }}
                </a>
                <div class="d-flex align-items-center">
                    <button 
                        @click="onClickSecondaryButton"
                        type="button"
                        class="btn btn-outline-secondary text-sm btn-block d-flex align-items-center me-3"
                    >
                        {{ secondaryButtonLabel }}
                    </button>
                    <button
                        @click="onClickPrimaryButton"
                        type="button"
                        class="btn btn-primary text-sm btn-block d-flex align-items-center"
                    >
                        {{ primaryButtonLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .mobile {
        width: 100% !important;
    }
}

.slide {
	position: fixed;
	right: -530px;
	top: 0;
	width: 520px;
	height: 100%;
	min-height: 600px;
	background: $white;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-ms-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	z-index: 1055;
	-webkit-box-shadow: -1px 0 10px 0 rgba($black, 0.4);
	-moz-box-shadow: -1px 0 10px 0 rgba($black, 0.4);
	box-shadow: -1px 0 10px 0 rgba($black, 0.4);

	.slide-header, .slide-footer {
		width: 100%;
		font-weight: bold;
		font-size: 20px;
		padding: 16px 25px;
	}

	.slide-header {
		border-bottom: 1px solid $gray-800;
	}

	.slide-footer {
		border-top: 1px solid $gray-800;
        .btn {
            height: 40px;
        }
	}

	.slide-body {
		width: 100%;
		height: calc(100vh - 146px);
		overflow: auto;
		padding: 16px 25px;

		label {
			font-weight: bold;
		}
        &.full-height {
            height: calc(100vh - 85px);
        }
        &.with-custom-footer {
            height: calc(100vh - 240px);
        }
        &.with-custom-footer-mobile {
            height: calc(100vh - 190px);
        }
        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
	}

    .btn-slider-close {
        width: 40px;
        height: 40px;
        border: 1px solid $gray-300;
        &:hover {
            background-color: rgba(108, 117, 125, 0.1);
        }
    }
}

.slide-in {
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-ms-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	right: 0 !important;
}
</style>

<script>
import { toRefs, watch } from 'vue';
import useConstants from '@/composables/useConstants';
import { setWithExpiry } from '@/helpers/storage.helper';

export default {
    props: {
        headerTitle: {
            type: String
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        primaryButtonLabel: {
            type: String,
            default: 'Save'
        },
        secondaryButtonLabel: {
            type: String,
            default: 'Cancel'
        },
        optionalButtonLabel: {
            type: String,
        },
        showSlider: {
            type: Boolean,
            default: false
        },
        clickOutsideClose: {
            type: Boolean,
            default: false
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        immediateClose: {
            type: Boolean,
            default: false
        },
        hasCustomFooter: {
            type: Boolean,
            default: false
        },
        customFooterBig: {
            type: Boolean,
            default: false
        },
        mobileNavigation: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close-slider', 'primary-button-event', 'secondary-button-event', 'back-button-event'],
    setup(props, { emit }) {
        const { showSlider, clickOutsideClose, immediateClose, showBackButton, showCloseButton } = toRefs(props);
        const { isMobile } = useConstants();

        const storeConsumerPrefix = window['env']['storeConsumerPrefix']; // eslint-disable-line

        const closeSlider = () => {
            emit('close-slider', true);
        }

        const onClickPrimaryButton = () => {
            emit('primary-button-event', true);
        }

        const onClickSecondaryButton = () => {
            emit('secondary-button-event', true);
        }

        const onClickOptionalButton = () => {
            emit('optional-button-event', true);
        }

        const onClickBackButton = () => {
            emit('back-button-event', true);
        }

        const detectBackButton = () => {
            window.onpopstate = function() {
                if (showBackButton.value) {
                    onClickBackButton()
                }

                if(showCloseButton.value) {
                    closeSlider()
                }
            };
            
        }

        watch(showSlider, (value) => {
            if (!isMobile) {
                const modalBackdrop = document.createElement('div');
                modalBackdrop.classList.add('slider', 'modal-backdrop');
                modalBackdrop.style.cssText = 'overflow:hidden; opacity: 0.75';
    
                if (clickOutsideClose.value) {
                    modalBackdrop.onclick = () => {
                        emit('close-slider', value);
                    }
                }
    
                if (value) {
                    document.body.appendChild(modalBackdrop);
                    document.body.style.cssText = 'overflow:hidden';
                } else {
                    if (immediateClose.value) {
                        const slider = document.querySelector('div.slider.modal-backdrop');
                        document.body.removeChild(slider);
                        document.body.style.cssText = 'overflow:initial;';
                    } else {
                        setTimeout(() => {
                            const slider = document.querySelector('div.slider.modal-backdrop');
                            if(slider) {
                              document.body.removeChild(slider);
                            }
                            document.body.style.cssText = 'overflow:initial;';
                        }, 300);
                    }
                }
            } else {
                detectBackButton()
                setWithExpiry(`${storeConsumerPrefix}slider`, value);
            }
        });

        return {
            closeSlider,
            onClickPrimaryButton,
            onClickSecondaryButton,
            onClickOptionalButton,
            onClickBackButton,
            isMobile,
            detectBackButton
        }
    }
}
</script>
