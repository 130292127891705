<template>
    <div :class="`${ custom ? 'custom-global-alert' : 'custom-alert' } ${type} navbar navbar-expand`">
        <div class="container d-flex d-flex justify-content-between">
            <ul class="mb-0 pr-2 list-unstyled">
                <li v-for="message in messages" :key="message"><img alt='alert-icon' v-if="!isMobile&&isShowIcon" class="cursor-pointer me-2" src="@/assets/icons/alert-icon.svg" @click="close"/> <span>{{ message }}</span></li>
            </ul>
            <img alt="close-white" v-if="isShowIcon" class="cursor-pointer ms-2" src="@/assets/icons/close-white.svg" @click="close"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String
        },
        messages: {
            type: Array
        },
        custom: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean
        },
        isShowIcon: {
            type: Boolean,
            default: false
        }
    },
    setup (props, {emit}) {

        const close = () => {
            emit('close')
        }

        setTimeout(() => {
            close()
        }, 5000);

        return {
            close
        }
    }
}
</script>

<style lang="scss">
    .custom-alert {
        padding: 15px 0;
        margin: 10px 0;
        &.success {
            background-color: $success-100;
            border-left: 4px solid $success-600;
        }
        &.warning {
            background-color: $warning-100;
            border-left: 4px solid $warning-200;
        }
        &.danger {
            background-color: $error-100;
            border-left: 4px solid $error-600;
        }
    }

    .custom-global-alert {
        padding: 15px 10px;
        margin: 0 0;
        &.success {
            background-color: $success-700;
            color: white;
        }
        &.warning {
            background-color: $warning-100;
            border-left: 4px solid $warning-200;
        }
        &.danger {
            background-color: $error-100;
            border-left: 4px solid $error-600;
        }
    }
</style>