import axios from 'axios';

class AuthService {
  login(params: {user: { email: string, password: string, rememberMe: boolean, uuid: string }, userType?: string}) {
      const user = params.user;
      const url = '/login' + (params.userType === 'admin' ? '/admin' : '');

    const postParams: any = { // eslint-disable-line
      email: user.email,
      password: user.password,
      remember_me: (user.rememberMe) ? 1 : 0,
    };

    if (user.uuid) {
      postParams.uuid = user.uuid;
    }

    return axios
      .post(url, postParams)
      .then(response => {
        return response;
      });
  }

  register(user: { email: string, password: string, uuid: string }) {
    const params = {
      email: user.email,
      password: user.password,
      password_confirmation: user.password,
    };

    if (user.uuid) {
      Object.assign(params, { uuid: user.uuid });
    }

    return axios
        .post('/register', params)
        .then(response => {
            return response;
        });
  }

  registerResend(email: string,) {
    return axios
        .post('/register-resend', {
            email: email
        })
        .then(response => {
            return response;
        });
  }

  verifyEmail(id: number, code: string) {
    return axios
        .post('/verify-email', {
          user_id: id,
          code: code
        })
        .then(response => {
            return response;
        });
  }

  logout(userType = 'user') {
    return axios
      .post(`/logout/${userType}`, {})
      .then(response => {
        return response;
      });
  }

  refresh(token: string) {
    return axios
      .post('/refresh', {
        token: token,
      })
      .then(response => {
        return response;
      });
  }

  me() {
    return axios
      .get('/me')
      .then(response => {
        return response;
      });
  }

  forgotPassword(email: string, user_type: string) {
    return  axios.post('/forgot-password', {
        email: email,
        user_type: user_type
    })
    .then(response => {
      return response;
    });
  }

  resetPassword(token: string, email: string, password: string, password_confirmation: string, user_type: string) {
    return  axios.post('/reset-password', {
      token: token,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      user_type: user_type,
    })
    .then(response => {
      return response;
    });
  }

  changePassword(user_type: string, email: string, current_password: string, new_password: string) {
    return  axios.post(`/change-password/${user_type}`, {
      email: email,
      current_password: current_password,
      new_password: new_password
    })  
    .then(response => {
      return response;
    });
  }

  getUserDetails() {
    return axios
        .get('/get-settings')
        .then(response => {
            return response;
        });
  }

  updateUserDetails(data:object){
    return  axios.post(`/update-settings`, data)
    .then(response => {
      return response;
    });
  }

  checkGrantToken(token: string){
    return axios.get(`/check-grant-token/${token}`)
    .then(response => {
      return response;
    });
  }

  grantToken(token: string){
    return axios.get(`/approve-request-access/${token}`)
        .then(response => {
          return response;
        });
  }
  
}

export default new AuthService();