import BaseLayout from '@/layouts/BaseLayout.vue';

export default [
	{
		path: '/application',
		component: () => import('@/views/Loan/IndexView.vue'),
		meta: {
			layout: BaseLayout,
			header: 'loan',
			requiredAuth: true
		},
		children: [{
			path: 'select-loan-type',
			name: 'loan-purpose',
			component: () => import('@/views/Loan/PurposeView.vue'),
			meta: {
				progress: 'Loan Purpose',
				step: 1
			},
		},
		{
			path: 'new-home-loan',
			name: 'new_home-loan',
			component: () => import('@/views/Loan/Information/NewHomeView.vue'),
			meta: {
				progress: 'Loan Information',
				step: 2
			},
		},
		{
			path: 'investment-loan',
			name: 'investment_home-loan',
			component: () => import('@/views/Loan/Information/InvestmentHomeView.vue'),
			meta: {
				progress: 'Loan Information',
				step: 2
			},
		},
		{
			path: 'refinance-loan',
			name: 'refinance_home-loan',
			component: () => import('@/views/Loan/Information/RefinanceHomeView.vue'),
			meta: {
				progress: 'Loan Information',
				step: 2
			},
		},
		{
			path: 'personal-loan',
			name: 'personal-loan',
			component: () => import('@/views/Loan/Information/PersonalView.vue'),
			meta: {
				progress: 'Loan Information',
				step: 2
			},
		},
		{
			path: 'vehicle-loan',
			name: 'vehicle-loan',
			component: () => import('@/views/Loan/Information/VehicleView.vue'),
			meta: {
				progress: 'Loan Information',
				step: 2
			},
		},
		{
			path: 'select-banks',
			name: 'bank-submit',
			component: () => import('@/views/Loan/BankView.vue'),
			meta: {
				progress: 'Bank Submit',
				step: 3
			},
		}
	]}
]
