import store from '@/store';
import ApplicationService from '@/services/application.service';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line

export default [
    {
        path: '/:catchAll(.*)',
        name: 'default',
        component: () => import('@/views/DefaultPageView.vue'),
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
            // Commented due to an error with the code that prevents the user from using the app.
            // Todo: Revise the code below and get it fixed.
            // First check if the user is authenticated, then check if the user has an application in progress.

            // const applicationUuid = sessionStorage.getItem(`${storeConsumerPrefix}application_uuid`);
            // console.log('My UUID', applicationUuid)
            // if (applicationUuid) {
            //     try {
            //         const app_details = await store.dispatch('application/getDetails', applicationUuid);
            //
            //         const { data } = app_details.data;
            //         const { application } = data;
            //
            //         if (application.status !== 'submitted') {
            //             next({ name: `app-${application.current_step}` });
            //             return;
            //         }
            //
            //         next({ name: 'consumer-applications' });
            //         return;
            //     } catch (error) {
            //         // console.log(error);
            //     }
            //
            //     return;
            // }
            //
            // try {
            //     // console.log('Generate 2')
            //     const application = await ApplicationService.getUuid();
            //     sessionStorage.setItem(`${storeConsumerPrefix}application_uuid`, application.data.data.uuid);
            //     next({ name: 'app-details' });
            //     return;
            // } catch (error) {
            //     // console.log(error);
            // }

            // Immediately redirect to login page.
            next({ name: 'login' });
        },
    }
]