<template>
  <nav class="col-md-3 col-lg-3 d-md-block bg-white sidebar collapse">
    <header class="navbar navbar-dark flex-md-nowrap p-0 mt-2">
        <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 d-flex w-100" href="#">
            <div class="logo-container d-flex justify-content-center align-items-center me-2">
                <img src="@/assets/images/logo.png" alt="logo" class="logo">
            </div>
            <span class="header-title text-xl font-weight-bold text-gray-900">Anteup</span>
            <span class="header-title sub-text text-xl font-weight-medium ps-1 d-none d-sm-block d-md-block text-gray-200">
              {{ authUser?.role == 0 ? 'Admin' : 'Lender' }}
            </span>
        </a>
    </header>

    <div id="admin-sidebar" class="position-sticky menus" :class="{'px-3': authUser?.role == 0}">
      <template v-if="authUser?.role != 0">
        <h6 class="sidebar-heading px-3 mt-4 mb-1">
          <span>Applications to Action</span>
        </h6>
        <ul class="nav flex-column mb-2 px-3">
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='new' ? 'active' : ''" :to="{ name: 'applications', params: { mode: 'new' }}">
                <i class="fa fa-pencil mx-2"></i> New Applications
                <span id="sidebar-badge-new" class="badge bg-primary rounded-pill fa-pull-right">{{ totals.new }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='shortlisted' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'shortlisted'}}">
                <i class="fa-regular fa-square-check mx-2"></i> Shortlisted Offers
                <span id="sidebar-badge-shortlisted" class="badge bg-primary rounded-pill fa-pull-right">{{ totals.shortlisted }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='pre-approved-applications' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'pre-approved-applications'}}">
                <i class="fa fa-dollar mx-2"></i> Pre-Approval Offers
                <span id="sidebar-badge-pre-approved-applications" class="badge bg-primary rounded-pill fa-pull-right">{{ totals['pre-approved-applications'] }}</span>
            </router-link>
          </li>
        </ul>

        <h6 class="sidebar-heading px-3 mt-4 mb-1">
          <span>Sitting with Client</span>
        </h6>
        <ul class="nav flex-column mb-2 px-3">
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='pending' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'pending'}}">
                <i class="fa fa-clock mx-2"></i> Pending Offers
                <span id="sidebar-badge-pending" class="badge bg-primary rounded-pill fa-pull-right">{{ totals.pending }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='pre-approved' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'pre-approved'}}">
                <i class="fa fa-dollar mx-2"></i> Pre-Approved Offers
                <span cid="sidebar-badge-pre-approved" class="badge bg-primary rounded-pill fa-pull-right">{{ totals['pre-approved'] }}</span>
            </router-link>
          </li>
        </ul>

        <h6 class="sidebar-heading px-3 mt-4 mb-1">
          <span>Completed Applications</span>
        </h6>
        <ul class="nav flex-column mb-2 px-3">
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='accepted' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'accepted'}}">
                <i class="fa fa-circle-check mx-2"></i> Accepted Offers
                <span id="sidebar-badge-accepted" class="badge bg-primary rounded-pill fa-pull-right">{{ totals.accepted }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name=='applications' && mode=='declined' ? 'active' : ''" :to="{name: 'applications', params: {mode: 'declined'}}">
                <i class="fa fa-circle-xmark mx-2"></i> Declined Offers
                <span id="sidebar-badge-declined" class="badge bg-primary rounded-pill fa-pull-right">{{ totals.declined }}</span>
            </router-link>
          </li>
        </ul>

        <ul class="nav flex-column my-4 px-3">
          <li class="nav-item">
            <router-link class="nav-link" :class="{'active': $route.name == 'admin-messages'}" :to="{ name: 'admin-messages' }">
                <i class="fa fa-message mx-2"></i> Messages
                <span class="badge bg-primary rounded-pill fa-pull-right">{{ totals.messages }}</span>
            </router-link>

          </li>
        </ul>

        <ul class="nav flex-column mb-2 px-3">
          <li class="nav-item" v-if="authUser?.role === 1">
            <router-link href="#" class="nav-link" :class="{'active': $route.name == 'lender-user-management'}" :to="{ name: 'lender-user-management' }">
                <i class="fa-solid fa-user mx-2"></i> Users
            </router-link>
          </li>
          <li class="nav-item" v-if="authUser?.role === 1">
            <router-link href="#" class="nav-link" :class="$route.name === 'regions-branches-teams-manager' ? 'active' : ''" :to="{name: 'regions-branches-teams-manager'}">
                <i class="fa fa-location-dot mx-2"></i> Regions, Branches &amp; Teams
            </router-link>
          </li>
          <li class="nav-item">
            <router-link href="#" class="nav-link" :class="$route.name === 'admin-settings' ? 'active' : ''" :to="{name: 'admin-settings'}">
                <i class="fa fa-gear mx-2"></i> Settings
            </router-link>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <router-link class="nav-link px-1" :class="$route.name=='dashboard' ? 'active' : ''" :to="{ name: 'dashboard'}">
                <i class="fa-solid fa-border-all mx-2"></i> Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-1" :class="$route.name=='application-management' ? 'active' : ''" :to="{ name: 'application-management'}">
                <i class="fa-solid fa-pen-to-square mx-2"></i> Applications
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-1" :class="$route.name=='lender-management' ? 'active' : ''" :to="{ name: 'lender-management' }">
                <i class="fa fa-compass mx-2"></i> Lenders
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-1" :class="$route.name=='consumer-management' ? 'active' : ''" :to="{ name: 'consumer-management' }">
                <i class="fa fa-users mx-2"></i> Consumers
            </router-link>
          </li>
          <li class="nav-item">
            <router-link href="#" class="nav-link px-1" :class="$route.name === 'admin-settings' ? 'active' : ''" :to="{name: 'admin-settings'}">
              <i class="fa fa-gear mx-2"></i> Settings
            </router-link>
          </li>
          <li class="nav-item position-absolute bottom-0 mb-3">
            <router-link class="nav-link px-1" :class="$route.name=='admin-management' ? 'active' : ''" :to="{ name: 'admin-management' }">
                <i class="fa fa-user mx-2"></i> Admin Users
            </router-link>
          </li>
        </ul>
      </template>
    </div>

    <div v-if="authUser" class="sidebar-footer border-top">
      <div class="d-flex align-items-center">
          <div class="avatar text-center p-2 me-3" v-if="authUser?.role != 0">
              <i class="fa fa-user"></i>
          </div>
          <div class="w-100">
              <div class="d-flex align-items-center">
                  <div class="font-weight-bold text-sm me-auto">{{ `${authUser.first_name} ${authUser.last_name} ` }}</div>
                  <a @click.prevent="logout" class="cursor-pointer ms-auto ms-2">
                      <i class="fa-solid fa-arrow-right-from-bracket text-secondary"></i>
                  </a>
              </div>
              <div class="text-secondary text-sm">{{ authUser.email }}</div>
          </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
header {
    height: auto;
}

.badge {
  font-family: $font-family-regular;
  background-color: $gray-100 !important;
  color: $gray-700;
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding: 0 10px;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
	.nav-link {
		font-family: $font-family-medium;
		color: $gray-700;
	}

  .nav-item > .nav-link.active {
    background: $gray-400;
    border-radius: 6px;
  }

  .nav-item.position-absolute {
    width: 90%;
  }

	.nav-link.active {
		color: $cyan-700;
	}
    .sidebar-heading {
      color: $gray-500;
    }

    .sidebar-footer {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 30px;
        background-color: #FFFFFF;
    }

    .menus {
        height: calc(100% - 180px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1; 
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #c1c1c1; 
        }
    }
}
.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: .5rem;
	overflow-x: hidden;
	overflow-y: auto;
}

.navbar-brand {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: 1rem;
}
.navbar {
	.navbar-toggler {
		top: .25rem;
		right: 1rem;
	}
}
</style>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import axios from "axios";
import { computed, ref } from "vue";
import useEmitter from '@/composables/useEmitter';
import useAuth from '@/composables/useAuth';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = useLoading();
    const store = useStore();
    const totals = ref({});
    const mode = computed(() => route.params.mode || 'new');
    const { emitter } = useEmitter();
    const { authUser } = useAuth();

    const getTotals = async () => {
      return await axios.get(`/admin/applications/summary`)
          .then(response => {
            const { data } = response.data;
            totals.value = data.totals;
          });
    }

    if (authUser.value?.role != 0) {
      getTotals();
    }

    const logout = async () => {
      const loader = loading.show({color: '#088AB2'});

      try {
        await store.dispatch('auth/logout', 'admin');
        loader.hide();
        router.push({ name: 'login' });
      } catch (err) {
        loader.hide();
      }
    }

    emitter.on('message-counter', (val) => {
      totals.value.messages = val;
    });

    return {
      authUser,
      mode, 
      totals, 
      logout
    }
  }
}
</script>
