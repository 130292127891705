export const setWithExpiry = (key: string, value: string, ttl = 0) => {
	if (ttl > 0) {
		const now = new Date();

		ttl *= 1000;

		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}

		localStorage.setItem(key, JSON.stringify(item));

		return;
	}

	localStorage.setItem(key, value);
}

export const getWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);

	if (!itemStr) {
		return null;
	}

	const isJsonParsable = (itemStr: string) => {
		try {
			JSON.parse(itemStr);
		} catch (e) {
			return false;
		}
		return true;
	}

	if (isJsonParsable(itemStr)) {
		const item = JSON.parse(itemStr);
		const now = new Date();

		if (item.hasOwnProperty('expiry')) { // eslint-disable-line
			if (now.getTime() > item.expiry) {
				localStorage.removeItem(key);
				return null;
			}
			return item.value;
		}
		
		return item;
	}

	return itemStr;
}