import BaseLayout from '@/layouts/BaseLayout.vue';

export default [
    {
        path: '/consumer',
        component: () => import('@/views/Consumer/IndexView.vue'),
        meta: {
            layout: BaseLayout,
            requiredAuth: true,
            header: 'application'
        },
        children: [{
                path: 'applications',
                name: 'consumer-applications',
                component: () => import('@/views/Consumer/MyApplicationView.vue'),
            },
            {
                path: 'statement-of-position',
                name: 'consumer-sop',
                component: () => import('@/views/Consumer/StatementOfPositionView.vue'),
            },
            {
                path: 'account-settings',
                name: 'consumer-settings',
                component: () => import('@/views/Consumer/AccountSettingsView.vue'),
            },
            {
                path: 'change-password',
                name: 'consumer-change-password',
                component: () => import('@/views/Consumer/ChangePasswordView.vue'),
            },
            {
                path: 'offer-list',
                name: 'consumer-offer-list',
                component: () => import('@/views/Consumer/Offers/OfferListView.vue'),
            },
        ],
    },
    {
        path: '/consumer/grant-admin-access',
        name: 'consumer-grant-admin-access',
        component: () => import('@/views/Consumer/GrantAdminAccess.vue'),
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/consumer/grant-admin-access/success',
        name: 'consumer-grant-admin-access-success',
        component: () => import('@/views/Consumer/GrantAdminAccessSuccess.vue'),
        meta: {
            requiredAuth: true,
        },
    }
]