import { computed } from "vue";
import store from '@/store';

export default function useAuth() {

    const authUser = computed(() => store.getters['auth/getAuthUser']);
    const isLoggedIn = computed(() => store.getters['auth/getIsLoggedIn']);

    return {
        isLoggedIn,
        authUser,
    }
}
