<template>
    <vue-final-modal 
        :classes="isMobile ? 'custom-mobile-modal-container' : 'custom-modal-container'" 
        :content-class="isMobile ? 'custom-mobile-modal-content' : 'custom-modal-content'"
        :click-to-close="clickToClose"
        @click-outside="$emit('click-outside-container', showModal)"
        :esc-to-close="false"
        :z-index="1050"
         v-model="modalOn"
        :lock-scroll="false"
    >
        <div v-show="showCloseButton" class="text-end">
          <button class="btn-close" @click="closeModal"></button>
        </div>
        <slot></slot>
        <div class="row" v-if="showFooterButtons">
            <div v-show="secondaryButton" class="col">
                <button type="button" :class="secondaryClass"  @click="$emit('secondary')">{{ secondary }}</button>
            </div>
            <div class="col">
                <button type="button" :class="primaryClass" @click="$emit('primary')">{{ primary }}</button>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import useConstants from '@/composables/useConstants';
import { ref, watch } from 'vue';
export default {
    props: {
        primary: {
            type: String,
            default: 'Confirm'
        },
        secondary: {
            type: String,
            default: 'Cancel'
        },
        secondaryButton:{
            type: Boolean,
            default: true,
        },
        primaryClass: {
            type: String,
            default: 'btn btn-danger w-100'
        },
        secondaryClass: {
            type: String,
            default: 'btn btn-outline-secondary w-100'
        },
        showModal: {
            type: Boolean,
            default: false,
            required: true
        },
        showFooterButtons: {
            type: Boolean,
            default: true
        },
        clickToClose: {
            type: Boolean,
            default: false
        },
        showCloseButton: {
          type: Boolean,
          default: false,
        }
    },
    setup(props, {emit}) {
        const modalOn = ref(false);
        const { isMobile } = useConstants();

        watch(() => props.showModal, (first) => {
            modalOn.value = first;
        });
        const closeModal = () => {

          emit('click-outside-container', true);
        }
        return { modalOn, closeModal, isMobile }
    }
}
</script>