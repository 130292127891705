import AuthLayout from '@/layouts/AuthLayout.vue';

export default [
    {
        path: '/login/:token?',
        name: 'login',
        component: () => import('@/views/Auth/LoginView.vue'),
        meta: {
            layout: AuthLayout,
            commonAuth: false
        },
    }, 
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Auth/RegisterView.vue'),
        meta: {
            layout: AuthLayout,
            commonAuth: false
        },
    },
    {
        path: '/register/success',
        name: 'register-success',
        component: () => import('@/views/Auth/RegisterSuccessView.vue'),
        meta: {
            layout: AuthLayout,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/Auth/ForgotPasswordView.vue'),
        meta: {
            commonAuth: false
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/Auth/ResetPasswordView.vue'),
        meta: {
            commonAuth: false
        }
    },
    {
        path: '/verify/success',
        name: 'verify-success',
        component: () => import('@/views/Auth/EmailVerifiedView.vue'),
        meta: {
            layout: AuthLayout,
            commonAuth: false
        },
    },
]

