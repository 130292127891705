import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue Overlay Loader
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Interceptors
import { httpTokenInterceptor } from './interceptors/http.token.interceptor'
import { httpErrorInterceptor } from './interceptors/http.error.interceptor'
httpTokenInterceptor();
httpErrorInterceptor();

// Fontawesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab) // Add all icons to the library so you can use it in your page
dom.watch();

// Axios global defaults
import axios from 'axios';
const API_URL = window['env' as any]['apiUrl' as any]; // eslint-disable-line
axios.defaults.baseURL = API_URL as any; // eslint-disable-line

// popup modal
import vfmPlugin from 'vue-final-modal'

import BootstrapVue3 from 'bootstrap-vue-3'

// Import the toast plugin here.
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// Hotjar integration
import vueHotjar from 'vue-hotjar-next';
const VUE_APP_HOTJAR_ID = window['env' as any]['VUE_APP_HOTJAR_ID' as any];

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VueLoading, { color: '#088AB2' })
    .use(vfmPlugin)
    .use(BootstrapVue3)
    .use(Toast)
    .use(vueHotjar, {
      id: VUE_APP_HOTJAR_ID
    })
    .mount('#app')
