import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import applicationRoute from './consumer/application.route';
import loanRoute from './consumer/loan.route';
import authRoute from './auth.route';
import consumerRoute from './consumer/consumer.route';
import defaultRoute from './consumer/default.route';
import { getWithExpiry } from '@/helpers/storage.helper';
import ApplicationService from '@/services/application.service';
import adminRoute from './admin/admin.route';
import store from "@/store";

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line
const storeAdminPrefix = window['env' as any]['storeAdminPrefix' as any]; // eslint-disable-line

const consumerRoutes: Array<RouteRecordRaw> = [
    ...authRoute,
    ...applicationRoute,
    ...consumerRoute,
    ...defaultRoute,
    ...loanRoute,
];

const adminRoutes: Array<RouteRecordRaw> = [
    ...adminRoute,
];

const host = window.location.host;
const routes = (host.includes('admin')) ? adminRoutes : consumerRoutes;

let canUpdate = true
let popStateDetected = false
window.addEventListener('popstate', () => {
    if (canUpdate) {
        popStateDetected = true
    }
})


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
});

router.beforeEach(async (to, from, next) => {
    const storagePrefix = (host.includes('admin')) ? storeAdminPrefix : storeConsumerPrefix;
    const accessToken = getWithExpiry(`${storagePrefix}access_token`);
    const showSlider = getWithExpiry(`${storagePrefix}slider`);
    const storedUser = getWithExpiry(`${storagePrefix}user`);

    if(to.meta.requiredAuth && !accessToken){

        if (storedUser && storedUser.remember_me == 1) {
            const refreshToken = getWithExpiry(`${storagePrefix}refresh_token`);
            await store.dispatch('auth/refresh', refreshToken).then(() => {
                next();
            });

            return
        }
        next({ name: 'login' });
        return;
    }

    const applicationUuid = sessionStorage.getItem(`${storeConsumerPrefix}application_uuid`);
    const commonPages = ['app-details', 'app-incomes', 'app-expenses', 'app-assets', 'app-liabilities'];
    const routeName: any = to.name // eslint-disable-line
    if (!applicationUuid&&!host.includes('admin')&&commonPages.includes(routeName)) {
        const application = await ApplicationService.getUuid();
        sessionStorage.setItem(`${storeConsumerPrefix}application_uuid`, application.data.data.uuid);
    }
    if (to.meta.commonAuth) {
        if (accessToken) {
            try {
                const application = await ApplicationService.getUuid();
                sessionStorage.setItem(`${storeConsumerPrefix}application_uuid`, application.data.data.uuid);

                try {
                    const applicationUuid = sessionStorage.getItem(`${storeConsumerPrefix}application_uuid`);
                    const app_details = await ApplicationService.getDetails(applicationUuid as string);

                    const { data } = app_details.data;
                    const { application } = data;

                    if (application.status !== 'submitted') {
                        next({ name: `app-${application.current_step}` });
                        return;
                    }

                    next({ name: 'consumer-applications' });
                    return;

                } catch (error) {
                    throw Error('Error fetching data');
                }

            } catch (error) {
                // console.log(error);
            }
        } else {
            localStorage.removeItem(`${storagePrefix}access_token`);
            localStorage.removeItem(`${storagePrefix}refresh_token`);
            localStorage.removeItem(`${storagePrefix}user`);
        }
    }

    if (to.meta.requiredAuth) {
        // if (!accessToken) {
        //     next({ name: 'login' });
        //     return;
        // } else {
        //     // to close the slider without going back to the previous page when back button was tap or clicked
        //     if (showSlider&&popStateDetected) {
        //         popStateDetected = false
        //         canUpdate = false
        //         setTimeout(() => {
        //             canUpdate = true
        //         }, 100);
        //         next(false);
        //         return;
        //     }
        // }

        if (accessToken) {
            if (showSlider&&popStateDetected) {
                popStateDetected = false
                canUpdate = false
                setTimeout(() => {
                    canUpdate = true
                }, 100);
                next(false);
                return;
            }
        }
    } else {
        if (to.name === 'login') {
            // console.log('Prevent Login')
            const samePage = from.name ? from.name : 'consumer-applications'
            if (accessToken) {
                next({ name: samePage });
                return;
            }
        } else {
            // to close the slider without going back to the previous page when back button was tap or clicked
            if (showSlider&&popStateDetected) {
                popStateDetected = false
                canUpdate = false
                setTimeout(() => {
                    canUpdate = true
                }, 100);
                next(false);
                return;
            }
        }
    }
    if(from.name === 'app-details'){
        window.location.href = to.path;
    }else {
        next();
    }
    // next();
});

export default router
