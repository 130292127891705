import {
    Application,
    LoanTypeDetails,
    LenderList,
    HomeLoanDetails,
    RefinanceLoanDetails,
    VehicleLoanDetails,
    PersonalLoanDetails,
    ApplicationAsset,
    ApplicationExpense,
    ApplicationLiability
} from '@/models';

import axios from 'axios';

class ApplicationService {
    getUuid() {
        return axios
            .get('/application/uuid')
            .then(response => {
                return response;
            });
    }

    getDetails(uuid: string) {
        return axios
            .get(`/application/${uuid}`)
            .then(response => {
                return response;
            });
    }

    getSopDetails(uuid = '') {
        let url = '/application/sop';
        if (uuid != '') {
            url += `/${uuid}`;
        }

        return axios
            .get(url)
            .then(response => {
                return response;
            });
    }

    /**
     * Export the Statement of Position to PDF.
     * @param uuid
     * @param lenderId
     */
    exportSopToPDF(uuid: string, lenderId: number) {
        // Define the API endpoint to get the PDF from.
        let url = '/application/sop/export';
        // If a UUID is provided, add it to the URL.
        if (uuid != '') {
            url += `/${uuid}`;
        }
        // If a lender ID is provided, add it to the URL as a param.
        url += '?lender_id=' + lenderId;
        // Return the response from the API.
        return axios
            .get(url)
            .then(response => {
                return response;
            });
    }

    /**
     * Get the list of lenders.
     */
    getLenderList() {
        // Return the response from the API.
        return axios
            .get('/lenders')
            .then(response => {
                return response;
            });
    }

    saveDetails(data: Application, uuid: string) {
        return axios
            .post(`/application/${uuid}/details`, data)
            .then(response => {
                return response;
            });
    }

    save(uuid: string) {
        return axios
            .post(`/application/${uuid}`, {})
            .then(response => {
                return response;
            });
    }

    updateStatus(data: { uuid: string, status: string }) {
        return axios
            .post(`/application/${data.uuid}/status`, { status: data.status, _method: 'PUT' })
            .then(response => {
                return response;
            });
    }

    deleteJointApplicant(uuid: string, joint_applicant_id: number) {
        return axios
            .post(`/application/${uuid}/joint-applicant/delete`, {
                joint_applicant_id: joint_applicant_id
            })
            .then(response => {
                return response;
            });
    }

    deleteDependent(data: { uuid: string, id: number }) {
        return axios
            .post(`/application/${data.uuid}/dependent/delete`, {
                id: data.id
            })
            .then(response => {
                return response;
            });
    }

	setLoanType(data: LoanTypeDetails) {
		return axios
            .post(`/application/save-loan-type`, data)
            .then(response => {
                return response;
            });
	}

	setLoanData(data: HomeLoanDetails|RefinanceLoanDetails|VehicleLoanDetails|PersonalLoanDetails) {
		return axios
            .post(`/application/save-loan-info`, data)
            .then(response => {
                return response;
            });
	}

	submitLender(data: LenderList) {
		return axios
            .post(`/application/submit-loan`, data)
            .then(response => {
                return response;
            });
	}

    saveIncomes(uuid: string, data: object) {
        return axios
            .post(`/application/${uuid}/incomes`, data)
            .then(response => {
                return response;
            });
    }
    
    removeIncome(data: { uuid: string, income_type: string, id: number }) {
        return axios
            .post(`/application/${data.uuid}/income/delete`, {
                uuid: data.uuid,
                type: data.income_type,
                id: data.id,
            })
            .then(response => {
                return response;
            });
    }

    saveAssets(data: { uuid: string, assets: ApplicationAsset }) {
        return axios
            .post(`/application/${data.uuid}/assets`, data.assets)
            .then(response => {
                return response;
            });
    }

    deleteAsset(data: { uuid: string, asset_type: string, id: number }) {
        return axios
            .post(`/application/${data.uuid}/asset/delete`, {
                type: data.asset_type,
                id: data.id,
            })
            .then(response => {
                return response;
            });
    }

    saveExpenses(data: { uuid: string, expenses: ApplicationExpense }) {
        return axios
            .post(`/application/${data.uuid}/expenses`, data.expenses)
            .then(response => {
                return response;
            });
    }

    deleteExpense(data: { uuid: string, expense_type: string, id: number }) {
        return axios
            .post(`/application/${data.uuid}/expense/delete`, {
                type: data.expense_type,
                id: data.id,
            })
            .then(response => {
                return response;
            });
    }

    saveLiabilities(data: { uuid: string, liabilities: ApplicationLiability }) {
        return axios
            .post(`/application/${data.uuid}/liabilities`, data.liabilities)
            .then(response => {
                return response;
            });
    }

    deleteLiability(data: { uuid: string, liability_type: string, id: number }) {
        return axios
            .post(`/application/${data.uuid}/liability/delete`, {
                type: data.liability_type,
                id: data.id,
            })
            .then(response => {
                return response;
            });
    }

    getApplicationList(){
        return axios
            .get(`/application/list`)
            .then(response => {
                return response;
            });
    }

    getOfferList(data : { uuid: string, sort_by: string}){
        return axios
            .get(`/application/${data.uuid}/offers?sort_by=${data.sort_by}`)
            .then(response => {
                return response;
            });
    }

    deleteDocument(data : { uuid: string, consumer_id: string, document_id: string, path: string}){
        return axios
            .post(`/${data.path}/${data.uuid}/document/delete`, {
                id: data.document_id,
                consumer_id: data.consumer_id,
            })
            .then(response => {
                return response;
            });
    }

    cancelApplication(uuid: string, formData: FormData){
        return axios
            .post(`/application/${uuid}/cancel`, formData)
            .then(response => {
                return response;
            });
    }

    updateShortlist(data : { uuid: string, id: string, type: string }){
        return axios
            .post(`/application/${data.uuid}/offer/shortlist`, {
                id: data.id,
                type: data.type,
            })
            .then(response => {
                return response;
            });
    }

    finaliseShortList( uuid: string, status: string){
        return axios
            .post(`/application/${uuid}/offer/finalise-shortlist`,{
                pre_approval: status,
            })
            .then(response => {
                return response;
            });
    }

    acceptFinalOffer( data : { uuid: string, id: string, type: string, path: string, reason_code: 0, description: ''}){
        return axios
            .post(`/application/${data.uuid}/offer/${data.path}`, {
                id: data.id,
                type: data.type,
                reason_code: data.reason_code,
                reason_description: data.description,
            })
            .then(response => {
                return response;
            });
    }

    submitPreapproveApplication( uuid: string, data: object){
        return axios
            .post(`/application/${uuid}/submit-final`, data)
            .then(response => {
                return response;
            });
    }

    getMessages(uuid: string) {
        return axios
            .get(`/application/${uuid}/messages`)
            .then(response => {
                return response;
            });
    }

    getMessageDetails(uuid: string, lenderId: number) {
        return axios
            .get(`/application/${uuid}/message/${lenderId}`)
            .then(response => {
                return response;
            });
    }

    sendMessage(uuid: string, data: FormData) {
        const headers = {};

        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('attachments')) {
            Object.assign(headers, {
                'Content-Type': 'multipart/form-data'
            });
        }

        return axios
            .post(`/application/${uuid}/message`, data, headers)
            .then(response => {
                return response;
            });
    }

    getDocumentLink(uuid: string, documentId: number) {
        return axios
            .get(`/application/${uuid}/${documentId}/document-link`)
            .then(response => {
                return response;
            });
    }

    saveFileToDocuments(uuid: string, documentId: number) {
        return axios
            .post(`/application/${uuid}/save-to-my-document`, {document_id: documentId})
            .then(response => {
                return response;
            });
    }

    readMessage(uuid: string, lenderId: number) {
        return axios
            .put(`/application/${uuid}/message/${lenderId}`)
            .then(response => {
                return response;
            });
    }
}

export default new ApplicationService();
