import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d231d9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container-fluid"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 1,
  class: "container d-flex flex-column vh-100"
}
const _hoisted_4 = { class: "row g-0 flex-grow-1 justify-content-center align-items-md-center" }
const _hoisted_5 = { class: "col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminSidebar = _resolveComponent("AdminSidebar")!
  const _component_AuthHeader = _resolveComponent("AuthHeader")!
  const _component_AuthFooter = _resolveComponent("AuthFooter")!

  return ($setup.showSidebar)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AdminSidebar),
          _createElementVNode("main", {
            class: _normalizeClass(["offset-3 col-9 d-table vh-100 vld-parent", {'px-3': !$setup.noPadding, 'py-4': !$setup.noPadding}]),
            id: "admin-main-container"
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2)
        ])
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_3, [
        _createVNode(_component_AuthHeader, { mode: "admin" }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        ($setup.route.name == 'login' || $setup.route.name == 'register')
          ? (_openBlock(), _createBlock(_component_AuthFooter, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
}