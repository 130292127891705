import { constants } from './constants.module';
import { application } from './application.module';
import { loan } from './loan.module';
import { createStore } from 'vuex'
import { auth } from './auth.module';
import { Application, User, Loan, Constants } from '@/models';

export interface State {
  status: {
    loggedIn: boolean
  },
  user: User,
  application: Application,
  loan: Loan,
  constants: Constants
}

export default createStore({
  modules: {
    auth,
    application,
    loan,
    constants
  }
})
