import { User } from '@/models';
import AuthService from '@/services/auth.service';
import { ActionContext } from "vuex";
import { State } from '.';
import { getWithExpiry, setWithExpiry } from '@/helpers/storage.helper';

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line 
const storeAdminPrefix = window['env' as any]['storeAdminPrefix' as any]; // eslint-disable-line

type Context = ActionContext<User, State>;

let storedUser = null;
if (getWithExpiry(`${storeConsumerPrefix}user`)) {
    storedUser = getWithExpiry(`${storeConsumerPrefix}user`) as any; // eslint-disable-line
} else {
    storedUser = getWithExpiry(`${storeAdminPrefix}user`) as any; // eslint-disable-line
}

const user = storedUser;
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,

    actions: {
        async login(context: Context, params: { user: { email: string, password: string, rememberMe: boolean, uuid: string }, userType?: string }) {
            const userType = params.userType || 'user';

            return await AuthService.login(params).then(
                response => {
                    const {data} = response.data;
                    const {auth} = data;

                    context.commit('setUser', data[userType]);
                    context.commit('setIsLoggedIn', true);

                    const storagePrefix = (userType === 'user') ? storeConsumerPrefix : storeAdminPrefix;

                    setWithExpiry(`${storagePrefix}user`, JSON.stringify(data[userType]));
                    setWithExpiry(`${storagePrefix}access_token`, auth.access_token, auth.expires_in);
                    setWithExpiry(`${storagePrefix}refresh_token`, auth.refresh_token);
                    
                    // eslint-disable-next-line no-prototype-builtins
                    if (userType === 'user' && data.hasOwnProperty('application')) {
                        sessionStorage.setItem(`${storeConsumerPrefix}application_uuid`, data.application.uuid);
                    }

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        async register(context: Context, user: { email: string, password: string, uuid: string }) {
            return await AuthService.register(user).then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        async logout(context: Context, userType = 'user') {
            return await AuthService.logout(userType).then(
                response => {
                    context.commit('setUser', null);
                    context.commit('setIsLoggedIn', false);

                    const storagePrefix = (userType === 'user') ? storeConsumerPrefix : storeAdminPrefix;

                    localStorage.removeItem(`${storagePrefix}user`);
                    localStorage.removeItem(`${storagePrefix}access_token`);
                    localStorage.removeItem(`${storagePrefix}refresh_token`);
                    localStorage.removeItem(`${storeConsumerPrefix}justVerified`);
                    sessionStorage.removeItem(`${storeConsumerPrefix}application_uuid`);
                    sessionStorage.removeItem(`${storeConsumerPrefix}grant_access_token`);

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        async refresh(context: Context, token: string, userType = 'user') {
            return await AuthService.refresh(token).then(
                response => {
                    const {data} = response.data;
                    const {auth} = data;

                    const storagePrefix = (userType === 'user') ? storeConsumerPrefix : storeAdminPrefix;

                    setWithExpiry(`${storagePrefix}access_token`, auth.access_token, auth.expires_in);
                    setWithExpiry(`${storagePrefix}refresh_token`, auth.refresh_token);

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        async me(context: Context) {
            return await AuthService.me().then(
                response => {
                    const {data} = response.data;

                    context.commit('setUser', data.user);
                    context.commit('setIsLoggedIn', true);

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    },

    getters: {
        getIsLoggedIn(state: State) {
            return state.status.loggedIn;
        },

        getAuthUser(state: State) {
            return state.user;
        }
    },

    mutations: {
        setUser(state: State, user: User) {
            state.user = user;
        },

        setIsLoggedIn(state: State, loggedIn: boolean) {
            state.status.loggedIn = loggedIn;
        }
    }
};