import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from '@/store';

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line

export default function useApplication() {
  const route = useRoute();
  const router = useRouter();

  const getDetails = async (uuid = null) => {
    sessionStorage.removeItem(`${storeConsumerPrefix}grant_access_token`);
    const applicationUuid = (uuid) ? uuid : sessionStorage.getItem(`${storeConsumerPrefix}application_uuid`);
    
    if (applicationUuid) {
      try {
        await store.dispatch('application/getDetails', applicationUuid);
      } catch (error) {
        throw Error('Error fetching data');
      }
    }
  }

  const validateAppDetails = () => {
    // if not app-details and app-details status == 0 redirect user to app-details
    const commonPages = ['app-incomes', 'app-expenses', 'app-assets', 'app-liabilities'];
    const routeName: any = route.name // eslint-disable-line
    if (commonPages.includes(routeName)&&stepStatuses.value.details == 0) {
      router.push(({ name: 'app-details' }))
    }
  }

  const formatJoints = (mains: any[], joints: any[]) => { // eslint-disable-line
    if (mains.length > 0 && joints.length > 0) {
        // Get the indexes of allow_joint === 0 main and joint dependents
        // then move the joint dependent to the index level of the main dependent 
        const jointIndexes = joints.reduce((arr, dependent, i) => ((dependent.allow_joint == 0) && arr.push(i), arr), [])
        const mainIndexes = mains.reduce((arr, dependent, i) => ((dependent.allow_joint == 0) && arr.push(i), arr), [])
        if (mainIndexes.length > 0 && mainIndexes.length > 0) {
            mainIndexes.forEach((mainIndex: number, i: number) => { 
                if (jointIndexes[i] != undefined && mainIndex != undefined) {
                    // moveJointDependent(jointIndexes[i], mainIndex) //(from, to)
                    joints.splice(mainIndex, 0, joints.splice(jointIndexes[i], 1)[0]);
                }
            })
        }

        // Get the indexes of allow_joint === 1 main and joint dependents
        // then move the joint dependent to the index level of the main dependent
        const jointAllowIndexes = mains.reduce((arr, dependent, i) => ((dependent.allow_joint == 1) && arr.push(i), arr), [])
        const mainAllowIndexes = joints.reduce((arr, dependent, i) => ((dependent.allow_joint == 1) && arr.push(i), arr), [])
        if (mainAllowIndexes.length > 0 && mainAllowIndexes.length > 0) {
            mainAllowIndexes.forEach((mainIndex: number, i: number) => { 
                if (jointAllowIndexes[i] != undefined && mainIndex != undefined) {
                    // moveJointDependent(jointAllowIndexes[i], mainIndex) // (from, to)
                    joints.splice(mainIndex, 0, joints.splice(jointAllowIndexes[i], 1)[0]);
                }
            })
        }

        return { mains: mains, joints: joints }
    }
  }

  const consumer = computed(() => store.getters['application/getConsumer']);
  const joint_applicant = computed(() => store.getters['application/getJointApplicant']);
  const has_joint_applicant = computed(() => store.getters['application/checkIfHasJointApplicant']);
  const stepStatuses = computed(() => store.getters['application/getStepStatuses']);
  const currentStep = computed(() => store.getters['application/getCurrentStep']);
  const status = computed(() => store.getters['application/getStatus']);
  const moneyMap = computed(() => store.getters['application/getMoneyMap']);
  const incomes = computed(() => store.getters['application/getIncomes']);
  const previousMainSalaryIncomes = computed(() => store.getters['application/getPreviousMainSalaryIncomes']);
  const previousJointSalaryIncomes = computed(() => store.getters['application/getPreviousJointSalaryIncomes']);
  const nonPreviousMainSalaryIncomes = computed(() => store.getters['application/getNonPreviousMainSalaryIncomes']);
  const nonPreviousJointSalaryIncomes = computed(() => store.getters['application/getNonPreviousJointSalaryIncomes']);
  const assets = computed(() => store.getters['application/getAssets']);
  const expenses = computed(() => store.getters['application/getExpenses']);
  const liabilities = computed(() => store.getters['application/getLiabilities']);
  const loan = computed(() => store.getters['application/getLoan']);
  const offer_cnt = computed(() => store.getters['application/getOfferCount']);

  return {
    getDetails,
    formatJoints,
    consumer,
    joint_applicant,
    stepStatuses,
    currentStep,
    has_joint_applicant,
    status,
    moneyMap,
    incomes,
    nonPreviousMainSalaryIncomes,
    nonPreviousJointSalaryIncomes,
    previousMainSalaryIncomes,
    previousJointSalaryIncomes,
    assets,
    expenses,
    liabilities,
    loan,
    offer_cnt,
    validateAppDetails
  }
}