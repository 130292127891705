import { ApplicationSalaryIncome } from './../models/application-salary-income.model';
import ApplicationService from '@/services/application.service';
import { Application } from '@/models';
import { ActionContext } from "vuex";
import { State } from '.';
import { reactive } from 'vue';
import { useLoading } from 'vue-loading-overlay';

const loading = useLoading({ color: '#088AB2' });

type Context = ActionContext<Application, State>;

export const application = {
    namespaced: true,
    state: {
        application: {
            uuid: 0,
            consumer: reactive({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                dob: '',
                current_address: {},
                previous_address: {},
                dependents: [],
            }),
            joint_applicant: reactive({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                dob: '',
                current_address: {},
                previous_address: {},
                dependents: [],
            }),
            current_step: '',
            step_statuses: {
                assets: false,
                bank_page: false,
                details: false,
                expenses: false,
                incomes: false,
                liabilities: false,
                loan_info_page: false,
                summary_page: false,
            },
            has_joint_applicant: false,
            incomes: {
                main: {
                    business_incomes: [],
                    other_incomes: [],
                    property_incomes: [],
                    salary_incomes: [],
                    trader_incomes: [],
                },
                joint: {
                    business_incomes: [],
                    other_incomes: [],
                    property_incomes: [],
                    salary_incomes: [],
                    trader_incomes: [],
                },
            },
            assets: {
                main: {
                    properties: [],
                    bank_accounts: [],
                    vehicles: [],
                    kiwisavers: [],
                    home_contents: [],
                    saving_investments: [],
                    others: [],
                },
                joint: {
                    properties: [],
                    bank_accounts: [],
                    vehicles: [],
                    kiwisavers: [],
                    home_contents: [],
                    saving_investments: [],
                    others: [],
                },
            },
            expenses: {
                main: {
                    living: [],
                    utilities: [],
                    discretionary: [],
                    insurance: [],
                    education: [],
                    health: [],
                    transport: [],
                    property: [],
                    other: [],
                    property_loan: [],
                    vehicle_loan: [],
                    kiwisaver: [],
                    student_loan: [],
                    credit_card: [],
                    overdraft: [],
                    personal_loan: [],
                },
                joint: {
                    living: [],
                    utilities: [],
                    discretionary: [],
                    insurance: [],
                    education: [],
                    health: [],
                    transport: [],
                    property: [],
                    other: [],
                    property_loan: [],
                    vehicle_loan: [],
                    kiwisaver: [],
                    student_loan: [],
                    credit_card: [],
                    overdraft: [],
                    personal_loan: [],
                },
            },
            liabilities: {
                main: {
                    student_loan: [],
                    credit_card: [],
                    overdraft: [],
                    personal_loan: [],
                    properties: [],
                    vehicles: [],
                },
                joint: {
                    student_loan: [],
                    credit_card: [],
                    overdraft: [],
                    personal_loan: [],
                    properties: [],
                    vehicles: [],
                },
            },
            status: '',
            useCache: false,
            loan_type: '',
            loan_info: [],
            applied_banks: [],
            offers_cnt: 0,
        }
    },

    getters: {
        getConsumer(state: State) {
            if (state.application.consumer == null) {
                return reactive({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    dob: '',
                    current_address: {
                        value: '',
                        duration: 0,
                        own_property: 0,
                        allow_joint: 0,
                    },
                    previous_address: {},
                    dependents: [],
                })
            }

            if (state.application.consumer.current_address == null) {
                state.application.consumer.current_address = {
                    value: '',
                    duration: 0,
                    own_property: 0,
                    allow_joint: 0
                };
            }

            return state.application.consumer;
        },

        getJointApplicant(state: State) {
            if (state.application.joint_applicant == null) {
                return reactive({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    dob: '',
                    current_address: {
                        value: '',
                        duration: 0,
                        own_property: 0,
                        allow_joint: 0,
                    },
                    previous_address: {},
                    dependents: [],
                })
            }

            return state.application.joint_applicant;
        },

        getStepStatuses(state: State) {
            return state.application.step_statuses;
        },

        getCurrentStep(state: State) {
            return state.application.current_step;
        },

        checkIfHasJointApplicant(state: State) {
            return state.application.has_joint_applicant;
        },

        getStatus(state: State) {
            return state.application.status;
        },

        getMoneyMap(state: State) {
            return state.application.my_money_map;
        },

        getIncomes(state: State) {
            return state.application.incomes;
        },

        getNonPreviousMainSalaryIncomes(state: State) {
            const incomes = [] as ApplicationSalaryIncome[];
            state.application.incomes.main.salary_incomes.forEach((income, key) => {
                if (income.is_previous_employer == 0) {
                    incomes.push({ ...income, index: key});
                }
            })
            return incomes;
        },

        getNonPreviousJointSalaryIncomes(state: State) {
            const incomes = [] as ApplicationSalaryIncome[];
            state.application.incomes.joint.salary_incomes.forEach((income, key) => {
                if (income.is_previous_employer == 0) {
                    incomes.push({ ...income, index: key});
                }
            })
            return incomes;
        },

        getPreviousMainSalaryIncomes(state: State) {
            const incomes = [] as ApplicationSalaryIncome[];
            state.application.incomes.main.salary_incomes.forEach((income, key) => {
                if (income.is_previous_employer == 1) {
                    incomes.push({ ...income, index: key});
                }
            })
            return incomes;
        },

        getPreviousJointSalaryIncomes(state: State) {
            const incomes = [] as ApplicationSalaryIncome[];
            state.application.incomes.joint.salary_incomes.forEach((income, key) => {
                if (income.is_previous_employer == 1) {
                    incomes.push({ ...income, index: key});
                }
            })
            return incomes;
        },

        getAssets(state: State) {
            return state.application.assets;
        },

        getExpenses(state: State) {
            return state.application.expenses;
        },

        getLiabilities(state: State) {
            return state.application.liabilities;
        },

        getLoan(state: State) {
            return reactive({
                loan_type: state.application.loan_type,
                loan_info: state.application.loan_info,
                applied_banks: state.application.applied_banks
            })
        },

        getOfferCount(state: State) {
            return state.application.offers_cnt;
        },

        
    },

    actions: {
        async getDetails(context: Context, uuid: string) {
            // Don't call api if the state is already been made
            // if (context.state.useCache) return;

            const loader = loading.show();

            return await ApplicationService.getDetails(uuid).then(
                response => {
                    loader.hide();

                    const { data } = response.data;

                    context.commit('setData', data.application);
                    // context.state.useCache = true;

                    return Promise.resolve(response);
                },
                error => {
                    loader.hide();

                    return Promise.reject(error);
                }
            );
        },

        async updateStatus(context: Context, data: { uuid: string, status: string }) {
            return await ApplicationService.updateStatus(data).then(
                response => {
                    const { data } = response.data;

                    context.commit('setData', data.application);
                    context.state.useCache = true;

                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

    },

    mutations: {
        setData(state: State, application: Application) {
            state.application = application;
        },

        setHasJointApplicant(state: State, val: boolean) {
            state.application.has_joint_applicant = val;
        },

        resetJointApplicant(state: State) {
            state.application.joint_applicant = null;
        },

        setStepStatus(state: State, type: string) {
            switch (type) {
                case 'incomes':
                    state.application.step_statuses.details = true;
                    state.application.step_statuses.incomes = true;
                    break;

                case 'expenses':
                    state.application.step_statuses.details = true;
                    state.application.step_statuses.incomes = true;
                    state.application.step_statuses.expenses = true;
                    break;

                case 'assets':
                    state.application.step_statuses.details = true;
                    state.application.step_statuses.incomes = true;
                    state.application.step_statuses.expenses = true;
                    state.application.step_statuses.assets = true;
                    break;

                case 'liabilities':
                    state.application.step_statuses.details = true;
                    state.application.step_statuses.incomes = true;
                    state.application.step_statuses.expenses = true;
                    state.application.step_statuses.assets = true;
                    state.application.step_statuses.liabilities = true;
                    break;
            }

        }
    }   
};