import BaseLayout from '@/layouts/BaseLayout.vue';

export default [
    {
        path: '/application',
        component: () => import('@/views/Application/IndexView.vue'),
        meta: {
            layout: BaseLayout,
            header: 'application'
        },
        redirect: '/details',
        children: [
            {
                path: 'details',
                name: 'app-details',
                component: () => import('@/views/Application/DetailsView.vue'),
                meta: {
                    stepper: 'Applicant(s) Details'
                },
            },
            {
                path: 'incomes',
                name: 'app-incomes',
                component: () => import('@/views/Application/IncomesView.vue'),
                meta: {
                    stepper: 'My Income'
                },
            },
            {
                path: 'expenses',
                name: 'app-expenses',
                component: () => import('@/views/Application/ExpensesView.vue'),
                meta: {
                    stepper: 'My Expenses'
                },
            },
            {
                path: 'assets',
                name: 'app-assets',
                component: () => import('@/views/Application/AssetsView.vue'),
                meta: {
                    stepper: 'My Assets'
                },
            },
            {
                path: 'liabilities',
                name: 'app-liabilities',
                component: () => import('@/views/Application/LiabilitiesView.vue'),
                meta: {
                    stepper: 'My Liabilities'
                },
            },
            {
                path: 'statement-of-position',
                name: 'app-statement-of-position',
                component: () => import('@/views/Application/SummaryView.vue'),
                meta: {
                    requiredAuth: true
                },
            }
        ]
    }
]