import useAuth from "@/composables/useAuth";
import AdminLayout from "@/layouts/Admin/AdminLayout.vue";
import BaseLayout from "@/layouts/Admin/BaseLayout.vue";

const { authUser } = useAuth();

const redirectName = (authUser.value?.role == 0) ? 'dashboard' : 'applications';
// Redirecting to the dashboard or applications causes an error if the user is not authenticated.
// Todo: First check if the user is authenticated, then redirect to the dashboard or applications. Otherwise redirect to the login page.

export default [
    {
        path: '/',
        component: () => import('@/views/Admin/IndexView.vue'),
        redirect: { name: 'login' }, // Was previously set to redirectName. This is a temporary fix.
        meta: {
            layout: AdminLayout,
            header: 'application',
            // requiredAuth: true,
        },
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/Admin/Auth/LoginView.vue'),
                meta: { showSidebar: false },
            },
            {
                path: 'forgot-password',
                name: 'admin-forgot-password',
                component: () => import('@/views/Admin/Auth/ForgotPasswordView.vue'),
                meta: { showSidebar: false },
            },
            {
                path: 'reset-password',
                name: 'admin-reset-password',
                component: () => import('@/views/Admin/Auth/ResetPasswordView.vue'),
                meta: { showSidebar: false },
            },
            {
                path: 'set-password/:token',
                name: 'new-user-set-password',
                component: () => import('@/views/Admin/Auth/SetPassswordView.vue'),
                meta: { showSidebar: false },
            },
            {
                path: 'applications/:mode?',
                name: 'applications',
                component: () => import('@/views/Admin/Applications/ApplicationView.vue'),
                meta: { showSidebar: true, requiredAuth: true, noPadding: false },
            },
            {
                path: 'offer',
                name: 'application-offer',
                component: () => import('@/views/Admin/Applications/ApplicationView.vue'),
                meta: { showSidebar: true, requiredAuth: true, noPadding: false },
            },
            {
                path: 'messages/:uuid?/:relatedId?',
                name: 'admin-messages',
                component: () => import('@/views/Admin/Applications/ApplicationMessageView.vue'),
                meta: { showSidebar: true, requiredAuth: true, noPadding: true },
            },
            {
                path: '/statement-of-position/:uuid?/:lenderId?',
                name: 'admin-statement-of-position',
                component: () => import('@/views/Admin/Applications/SummaryView.vue'),
                meta: {
                    layout: BaseLayout,
                    requiredAuth: true
                },
            },
            {
                path: '/settings',
                name: 'admin-settings',
                component: () => import('@/views/Admin/SettingsView.vue'),
                meta: {
                    layout: AdminLayout,
                    header: 'Settings',
                    showSidebar: true,
                    requiredAuth: true
                },
            },
            {
                path: '/regions-branches-teams',
                name: 'regions-branches-teams-manager',
                component: () => import('@/views/Admin/Lender/RegionBranchesTeamView.vue'),
                meta: {
                    layout: AdminLayout,
                    header: 'Regions, Branches & Teams',
                    showSidebar: true,
                    requiredAuth: true
                },
            },
            {
                path: '/lender-user-management',
                component: () => import('@/views/Admin/IndexView.vue'),
                meta: {
                    layout: AdminLayout,
                    header: 'Lender User Management',
                    requiredAuth: true
                },
                children: [
                    {
                        path: ':region?/:branch?/:team?',
                        name: 'lender-user-management',
                        component: () => import('@/views/Admin/Lender/UserManagementView.vue'),
                        meta: {
                            showSidebar: true,
                        },
                    },
                    {
                        path: 'user/:user_id',
                        name: 'lender-user',
                        component: () => import('@/views/Admin/Lender/UserDetailsView.vue'),
                        meta: {
                            showSidebar: false,
                            requiredAuth: true,
                            noPadding: false ,
                            layout: BaseLayout,
                        },
                    }
                ]
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/Admin/SuperAdmin/DashboardView.vue'),
                meta: { showSidebar: true },
            },
            {
                path: '/application-management',
                component: () => import('@/views/Admin/IndexView.vue'),
                meta: { showSidebar: true },
                children: [
                    {
                        path: ':mode?',
                        name: 'application-management',
                        component: () => import('@/views/Admin/SuperAdmin/Application/ListView.vue'),
                    },
                ]
            },
            {
                path: '/lender-management',
                component: () => import('@/views/Admin/IndexView.vue'),
                meta: { showSidebar: true },
                children: [
                    {
                        path: '',
                        name: 'lender-management',
                        component: () => import('@/views/Admin/SuperAdmin/Lender/ListView.vue'),
                    },
                    {
                        path: 'details/:lender_id',
                        name: 'lender-details',
                        component: () => import('@/views/Admin/SuperAdmin/Lender/DetailsView.vue'),
                        meta: {
                            showSidebar: false,
                            requiredAuth: true,
                            noPadding: false,
                            layout: BaseLayout,
                        },
                    }
                ]
            },
            {
                path: '/consumer-management',
                component: () => import('@/views/Admin/IndexView.vue'),
                meta: { showSidebar: true },
                children: [
                    {
                        path: '',
                        name: 'consumer-management',
                        component: () => import('@/views/Admin/SuperAdmin/Consumer/ListView.vue'),
                    },
                    {
                        path: 'details/:consumer_id',
                        name: 'consumer-details',
                        component: () => import('@/views/Admin/SuperAdmin/Consumer/DetailsView.vue'),
                        meta: {
                            showSidebar: false,
                            requiredAuth: true,
                            noPadding: false,
                            layout: BaseLayout,
                        },
                    }
                ]
            },
            {
                path: '/admin-management',
                component: () => import('@/views/Admin/IndexView.vue'),
                meta: { showSidebar: true },
                children: [
                    {
                        path: '',
                        name: 'admin-management',
                        component: () => import('@/views/Admin/SuperAdmin/Admin/ListView.vue'),
                    },
                    {
                        path: 'user/:user_id',
                        name: 'admin-user-management',
                        component: () => import('@/views/Admin/SuperAdmin/Admin/UserView.vue'),
                        meta: {
                            showSidebar: false,
                            requiredAuth: true,
                            noPadding: false ,
                            layout: BaseLayout,
                        },
                    },

                ]
            }

        ],
    }
]
