<template>
    <footer class="d-flex pb-4" style="color: #667085">
        © Anteup {{ year }}
    </footer>
</template>
<script>
import { ref } from 'vue';
export default {
    setup() {
        const year = ref('');
        let d = new Date();
        year.value = d.getFullYear();
        return { year }
    }
}
</script>