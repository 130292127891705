import router from '@/router';
import store from '@/store';
import axios, { AxiosResponse } from 'axios';
import { getWithExpiry } from '@/helpers/storage.helper';
import { useToast } from "vue-toastification";

const storeConsumerPrefix = window['env' as any]['storeConsumerPrefix' as any]; // eslint-disable-line 
const storeAdminPrefix = window['env' as any]['storeAdminPrefix' as any]; // eslint-disable-line 

export function httpErrorInterceptor() {
    axios.interceptors.response.use((response: AxiosResponse) => {
        return response;
    }, async (err) => {
        const originalConfig = err.config;
        const { data } = err.response;
        const storagePrefix = (data.user_type === 'user') ? storeConsumerPrefix : storeAdminPrefix;

        if (originalConfig.url !== "/login" && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                let storedUser = null;
                if (getWithExpiry(`${storagePrefix}user`)) {
                    storedUser = getWithExpiry(`${storagePrefix}user`) as any; // eslint-disable-line
                } 
                
                // else {
                //     localStorage.removeItem(`${storagePrefix}user`);
                //     localStorage.removeItem(`${storagePrefix}access_token`);
                //     localStorage.removeItem(`${storagePrefix}refresh_token`);
                // }

                if (storedUser && storedUser.remember_me == 1) {
                    try {
                        const refreshToken = getWithExpiry(`${storagePrefix}refresh_token`);
                        store.dispatch('auth/refresh', refreshToken).then(() => {
                            window.location.reload();
                        });
                    } catch (_error) {
                        await router.push('/login');
                    }

                    return;
                }

                await router.push('/login');
            }

        }

        // if (!data.data.fields?.new_password && data.user_type === 'user') {
        //     localStorage.removeItem(`${storagePrefix}user`);
        //     localStorage.removeItem(`${storagePrefix}access_token`);
        //     localStorage.removeItem(`${storagePrefix}refresh_token`);
        // } else {
        //     return Promise.reject(err)
        // }

        // Show a toast message if the error response contains a message.
        if ( err && err.response && err.response.data && err.response.data.message ) {
            const toast = useToast();
            toast.error(err.response.data.message);
        }

        return Promise.reject(err)
    });
}