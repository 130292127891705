
import { useRoute } from "vue-router";
import { computed } from "vue";
import AdminSidebar from "@/components/Admin/AdminSidebar.vue";
import AuthHeader from "@/components/Common/AuthHeader.vue";
import AuthFooter from "@/components/Common/AuthFooter.vue";

export default {
  components: {
    AuthFooter,
    AuthHeader,
    AdminSidebar
  },
  setup() {
    const route = useRoute();
    const showSidebar = computed(() => route.meta.showSidebar)
    const noPadding = computed(() => route.meta.noPadding)
    
    return { 
      route,
      showSidebar,
      noPadding 
    }
  }
}
