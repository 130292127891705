
import {useRoute} from 'vue-router'
import {computed} from 'vue';

export default {
  components: {
    // BaseHeader,
    // LoanHeader
  },
  setup() {
    const route = useRoute();
    const currentHeader = computed(() => route.meta.header)

    return {currentHeader}
  }
}
